import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import cloneDeep from 'lodash.clonedeep';
import useStateRef from 'react-usestateref';
import { API } from '@aws-amplify/api';
import { PubSub } from '@aws-amplify/pubsub';

import Row from './Row';
import DashboardTableHead from './DashboardTableHead';
import DashboardTableToolbar from './DashboardTableToolbar';

import getSerialFromTopic from '../../utils/getSerialFromTopic';

import { sortData, getSorting } from '../../utils/sortTable';
import filterData from '../../utils/filterData';

// const LIVEWERTE_ANZAHL = 60;
const LIVEWERTE_ANZAHL = 12;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      paddingLeft: 12,
      paddingTop: 10,
    },
  },
  paginationActions: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiIconButton-root': {
        padding: 0,
      },
      marginLeft: 8,
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  paginationInput: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 8,
    },
  },

}));

function DashboardTable() {
  const classes = useStyles();

  const [order, setOrder] = useState<SortingOrder>('asc');
  const [orderBy, setOrderBy] = useState('serialNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [emiData, setEmiData, emiDataRef] = useStateRef<DeviceWithShadow[]>([]);

  const [filteredData, setFilteredData] = useState<DeviceWithShadow[]>([]);
  const [loading, setLoading] = useState(false);

  const liveSub = useRef<MqttSubscription | undefined>(undefined);

  const [loadingLiveData, setLoadingLiveData] = useState(false);
  const [, setLiveDataDuration, liveDataDurationRef] = useStateRef<undefined | number>(undefined);
  const [requestedLiveValuesOnce, setRequestedLiveValuesOnce] = useState(false);

  const [filter, setFilter] = useState('');

  const timerInterval = useRef<undefined | NodeJS.Timer>();

  const handleRequestSort = useCallback((_: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }, [order, orderBy]);

  function handleChangePage(_: any, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: any) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onRemove = useCallback((serialNumber: string) => {
    const copy = cloneDeep(emiDataRef.current);
    emiDataRef.current.forEach((device: Device, index: number) => {
      if (device.serialNumber === serialNumber) {
        copy.splice(index, 1);
      }
    });
    setEmiData(copy);
    // eslint-disable-next-line
  }, []);

  const handleFinishedEdit = useCallback((newDescription: string, serialNumber: string) => {
    const copy = cloneDeep(emiDataRef.current);
    copy.forEach((row, index) => {
      if (row.serialNumber === serialNumber) {
        copy[index].bezeichnung = newDescription;
      }
    });
    setEmiData(copy);
    // eslint-disable-next-line
  }, []);

  function mapMqttResponse(value: MqttResponse, isEmicon: boolean) {
    if (isEmicon) {
      /* Falls Emicon Werte in Tabelle müssen */
      const serialNumber = getSerialFromTopic(value);
      // console.log({ serialNumber })
      // console.log({ value })
      const data: Shadow & Partial<AdditionalDeviceInfo> = {
        nox: value?.state?.reported?.Var_NOx_Out_mgO2 ?? value?.state?.reported?.nox ?? null,
        temp: value?.state?.reported?.Var_T_Exhaust ?? value?.state?.reported?.temp ?? null,
        // co: value?.state?.reported?.co ?? null,
        // co_m: value?.state?.reported?.co_m ?? null,
        // o2_f: value?.state?.reported?.o2_f ?? null,
        // status: value?.state?.reported?.status ?? null,
        // timestamps: value?.metadata.reported,
      };

      // console.log({ data })

      /*
        Livedaten in gesamtes Datenobjekt einordnen um sortieren zu können
      */

      const copy = cloneDeep(emiDataRef.current);
      copy.some((row, index) => {
        if (row.serialNumber === serialNumber) {
          // const hideValue = copy[index].status === 'offline'
          //   || copy[index].status === 'passiv'
          //   || data.status === 'passiv'
          //   || data.status === 'offline';
          const hideValue = false;
          copy[index].nox = hideValue ? null : data.nox;
          copy[index].temp = hideValue ? null : data.temp;
          // copy[index].co = hideValue ? null : data.co;
          // copy[index].co_m = data.co_m ?? copy[index].co_m;
          // copy[index].co_m_sort = hideValue ? null : copy[index].co_m;
          // copy[index].o2_f = hideValue ? null : data.o2_f;
          // copy[index].status = data.status ?? copy[index].status;
          // copy[index].timestamps = ({ ...data.timestamps, ...copy[index].timestamps }) as unknown as ShadowMetadata;
        }
        return row.serialNumber === serialNumber;
      });
      setEmiData(copy);
    } else {

      if (value?.state?.desired === null) {
        setLoadingLiveData(false);
        setRequestedLiveValuesOnce(true);
        return;
      }
      if (value?.state?.desired?.mqtt?.counter) {
        return;
      }
      const serialNumber = getSerialFromTopic(value);
      const data: Shadow & Partial<AdditionalDeviceInfo> = {
        co: value?.state?.reported?.co ?? null,
        co_m: value?.state?.reported?.co_m ?? null,
        nox: value?.state?.reported?.nox ?? null,
        o2_f: value?.state?.reported?.o2_f ?? null,
        temp: value?.state?.reported?.temp ?? null,
        status: value?.state?.reported?.status ?? null,
        timestamps: value?.metadata.reported,
      };
      /*
        Livedaten in gesamtes Datenobjekt einordnen um sortieren zu können
      */
      const copy = cloneDeep(emiDataRef.current);
      copy.some((row, index) => {
        if (row.serialNumber === serialNumber) {
          const hideValue = copy[index].status === 'offline'
            || copy[index].status === 'passiv'
            || data.status === 'passiv'
            || data.status === 'offline';
          copy[index].co = hideValue ? null : data.co;
          copy[index].co_m = data.co_m ?? copy[index].co_m;
          copy[index].co_m_sort = hideValue ? null : copy[index].co_m;
          copy[index].nox = hideValue ? null : data.nox;
          copy[index].o2_f = hideValue ? null : data.o2_f;
          copy[index].temp = hideValue ? null : data.temp;
          copy[index].status = data.status ?? copy[index].status;
          copy[index].timestamps = ({ ...data.timestamps, ...copy[index].timestamps }) as unknown as ShadowMetadata;
        }
        return row.serialNumber === serialNumber;
      });
      setEmiData(copy);
    }
  }

  async function requestLiveValues() {
    const liveTopics: string[] = [];
    const liveTopicsEmicon: string[] = [];
    emiDataRef.current.forEach(({ serialNumber, isEmicon }) => {
      if (isEmicon) {
        /* Falls Emicon Werte in Tabelle müssen */
        // console.log(serialNumber)
        PubSub.publish(`EMI-CON_${serialNumber}/EMI-CON/AWSDaten`, true);
        PubSub.publish(`$aws/things/EMI-CON_${serialNumber}/shadow/get`, {});
        liveTopicsEmicon.push(`$aws/things/EMI-CON_${serialNumber}/shadow/update/accepted`);
      }
      liveTopics.push(`$aws/things/EMI-LOG_${serialNumber}/shadow/update/accepted`);
    });

    if (requestedLiveValuesOnce === false) {
      if (liveTopicsEmicon.length > 0) {
        /* Falls Emicon Werte in Tabelle müssen. */
        liveSub.current = PubSub.subscribe(liveTopicsEmicon).subscribe({
          next: ({ value }) => {
            console.log('Sub Live Update Emicon');
            mapMqttResponse(value, true);
          },
          error: ((err) => {
            console.log('Sub Live Update Error Emicon');
            console.log(err);
          }),
        });
      }

      /* ENI-LOG */

      liveSub.current = PubSub.subscribe(liveTopics).subscribe({
        next: ({ value }) => {
          mapMqttResponse(value, false);
        },
        error: (error) => console.error(error),
      });
    }

    setTimeout(() => {
      emiDataRef.current.forEach(({ serialNumber, isEmicon }) => {
        if (isEmicon) {
          return;
        }
        PubSub.publish(`$aws/things/EMI-LOG_${serialNumber}/shadow/update`, {
          state: {
            desired: {
              mqtt: {
                counter: LIVEWERTE_ANZAHL, // Anzahl
                rate: 5, // frequenz max 5 sekunden
              },
            },
          },
        });
      });
      setLiveDataDuration(LIVEWERTE_ANZAHL * 5 * 1000);
      if (timerInterval.current) {
        //@ts-ignore
        clearInterval(timerInterval.current);
        timerInterval.current = undefined;
      }
      timerInterval.current = setInterval(() => {
        if (liveDataDurationRef.current) {
          if (liveDataDurationRef.current <= 0) {
            clearInterval(timerInterval.current as NodeJS.Timeout);
          }
          setLiveDataDuration(liveDataDurationRef.current - 1000);
        }
      }, 1000);
    }, 4000);
  }

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const res = (await API.get('emilog', 'dataWithShadow', {}));

      /*
        Referenz wird hier erstellt, ggf anpassen falls Probleme entstehen
      */

      setEmiData(res);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [setEmiData]);

  useEffect(() => {
    fetch();
    return function cleanUp() {
      liveSub?.current?.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredData(filterData(emiData, filter));
  }, [filter, emiData]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const headCells = [
    {
      id: 'serialNumber', numeric: false, label: 'Seriennummer',
    },
    {
      id: 'motorennummer', numeric: false, label: 'Motorennummer',
    },
    {
      id: 'bezeichnung', numeric: false, label: 'Bezeichnung',
    },
    {
      id: 'status', numeric: false, label: 'Status',
    },
    {
      id: 'nox', numeric: true, label: 'Live NOₓ in mg/Nm³*',
    },
    {
      id: 'co_m_sort', numeric: true, label: 'HMW CO in mg/Nm³*',
    },
    {
      id: 'temp', numeric: true, label: 'Live Temperatur in °C',
    },
    {
      id: 'betriebsstunden', numeric: true, label: 'Betriebsstd.',
    },
    {
      id: 'ausfallstunden', numeric: true, label: 'Ausfallstd.',
    },
    { // NEU
      id: 'Messtermin', numeric: false, label: 'Messtermin',
    },
    { // NEU
      id: 'Servicevertrag', numeric: false, label: 'Servicevertrag',
    },
    { // NEU
      id: 'ServiceVertragsende', numeric: false, label: 'Ende Servicevertrag',
    },
    {// NEU
      id: 'ausfalltagePercent', numeric: false, label: 'Ausfalltage (Jahr/%)',
    },
    {// NEU
      id: 'ausfallstundenPercent', numeric: false, label: 'Ausfallstunden (Jahr/%)',
    },
    // {// NEU
    //   id: 'Ausfallstunden', numeric: false, label: 'Ausfallstunden (Jahr)',
    // },
  ];

  // console.log({ filteredData })

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <DashboardTableToolbar
          devices={emiData}
          onNew={fetch}
          setFilter={setFilter}
          requestLiveValues={requestLiveValues}
          setLoadingLiveData={setLoadingLiveData}
          loadingLiveData={loadingLiveData}
          liveDataDuration={liveDataDurationRef.current}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="Tabelle"
          >
            <DashboardTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {loading ? (
                <>
                  {[...Array(10)].map((_i, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={`placeholder-${index}`}>
                      <TableCell colSpan={Object.keys(headCells).length + 3} style={{ padding: 16 }}>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {(sortData(filteredData, getSorting(order, orderBy)) as DeviceWithShadow[])
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rowData) => (
                      <Row
                        data={rowData}
                        key={rowData.serialNumber}
                        editFinished={handleFinishedEdit}
                        onRemove={onRemove}
                        requestedLiveValuesOnce={requestedLiveValuesOnce}
                      />
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </div>
        <div className={classes.tableFooter}>
          <div style={{ alignSelf: 'center' }}>
            * Trocken, 5 % Referenzsauerstoffgehalt
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData ? filteredData.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Geräte pro Seite"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              actions: classes.paginationActions,
              toolbar: classes.paginationToolbar,
              input: classes.paginationInput,
            }}
          />
        </div>
      </Paper>
    </div>
  );
}

export default React.memo(DashboardTable);
