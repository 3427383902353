/* eslint-disable no-param-reassign */
export default function filterData(data: Device[], filter: string) {
  const filterLower = filter.toLowerCase();
  const filtered = data.filter(
    (device) => {
      if (typeof device.bezeichnung === 'undefined') {
        device.bezeichnung = '';
      }
      if (typeof device.motorennummer === 'undefined') {
        device.motorennummer = '';
      }
      if (typeof device.Servicevertrag === 'undefined') {
        device.Servicevertrag = '';
      }
      return (
        device.serialNumber.toLowerCase().includes(filterLower)
        || device.bezeichnung.toLowerCase().includes(filterLower)
        || device.motorennummer.toLowerCase().includes(filterLower)
        || device.Servicevertrag.toLowerCase().includes(filterLower)
      );
    },
  );
  return filtered;
}
