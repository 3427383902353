/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

// const headCells = [
//   {
//     id: 'serialNumber', numeric: false, label: 'Seriennummer',
//   },
//   {
//     id: 'motorennummer', numeric: false, label: 'Motorennummer',
//   },
//   {
//     id: 'bezeichnung', numeric: false, label: 'Bezeichnung',
//   },
//   {
//     id: 'status', numeric: false, label: 'Status',
//   },
//   {
//     id: 'nox', numeric: true, label: 'Live NOₓ in mg/Nm³*',
//   },
//   {
//     id: 'co_m_sort', numeric: true, label: 'HMW CO in mg/Nm³*',
//   },
//   {
//     id: 'temp', numeric: true, label: 'Live Temperatur in °C',
//   },
//   {
//     id: 'betriebsstunden', numeric: true, label: 'Betriebsstd.',
//   },
//   {
//     id: 'ausfallstunden', numeric: true, label: 'Ausfallstd.',
//   },
//   { // NEU
//     id: 'Servicevertrag', numeric: false, label: 'Servicevertrag',
//   },
//   { // NEU
//     id: 'ServiceVertragsende', numeric: false, label: 'Ende Servicevertrag',
//   },
//   { // NEU
//     id: 'Messtermin', numeric: false, label: 'Messtermin',
//   },
// ];

function DashboardTableHead({
  order, orderBy, onRequestSort, headCells
}) {
  const classes = useStyles();
  DashboardTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell /> {/* Chart Dropdown */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        
        <TableCell
          key="csvDownload"
          align="center"
        >
          CSV
        </TableCell>

        <TableCell
          key="berichtDownload"
          align="center"
        >
          Bericht
        </TableCell>

        <TableCell /> {/* Punktemenü */}

      </TableRow>
    </TableHead>
  );
}

// export default DashboardTableHead;
export default React.memo(DashboardTableHead);
