function convertDate(timestamp: number | undefined) {
  if (typeof timestamp === 'undefined') {
    return '-';
  }
  const time = new Date(timestamp * 1000).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  if (Number.isNaN(time)) return '-';
  return time;
}

export default convertDate;
