/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';
import Feedback from '../Feedback';
import LoaderButton from '../LoaderButton';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  datePicker: {
    width: '100%',
    marginTop: '0px',
  },
  mb: {
    marginBottom: '8px',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  servicePlanned: {
    background: 'green',
    color: 'white',
  },
  serviceNotPlanned: {
    background: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  content: {
    marginTop: theme.spacing(1),
    flex: 1,
  },

}));

interface EmiconBerichtDeckblattInfoProps {
  serialNumber: string
  isEmicon: boolean
  deckblattInfoFromDevice: DeckblattInfo
}

export default function EmiconBerichtDeckblattInfo({ serialNumber, isEmicon, deckblattInfoFromDevice }: EmiconBerichtDeckblattInfoProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deckblattInfo, setDeckblattInfo] = useState<DeckblattInfo>(deckblattInfoFromDevice);

  const active = serialNumber !== undefined;

  // console.log({ serialNumber })
  // console.log({ isEmicon })

  async function handleSubmit() {
    try {
      setLoading(true);
      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber: serialNumber,
          isEmicon: isEmicon, // ACHTUNG
          fields: {
            DeckblattInfo: deckblattInfo,
          },
        },
      });
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {active ? (
          <>
            {/* Betreiberinfos */}

            <CardHeader
              title="Betreiber Informationen"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6} >
                <TextField
                  size="small"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.BetreiberName || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberName: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  size="small"
                  label="Straße"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.BetreiberStraße || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberStraße: e.target.value })}
                  className={classes.mb}
                />
              </Grid>

              <Grid item lg={6}>
                <TextField
                  size="small"
                  label="Postleitzahl"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={deckblattInfo.BetreiberPostleitzahl || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberPostleitzahl: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  size="small"
                  label="Ort"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.BetreiberOrt || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberOrt: e.target.value })}
                  className={classes.mb}
                />
              </Grid>
            </Grid>

            {/* Anlageninfos */}

            <CardHeader
              title="Anlagen Informationen"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6}>
                <TextField
                  size="small"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.AnlagenName || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenName: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  size="small"
                  label="Straße"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.AnlagenStraße || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenStraße: e.target.value })}
                  className={classes.mb}
                />
              </Grid>

              <Grid item lg={6}>
                <TextField
                  size="small"
                  label="Postleitzahl"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={deckblattInfo.AnlagenPostleitzahl || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenPostleitzahl: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  size="small"
                  label="Ort"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.AnlagenOrt || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenOrt: e.target.value })}
                  className={classes.mb}
                />
              </Grid>
            </Grid>

            {/* Anlagenbezeichnung */}

            <CardHeader
              title="Anlagen Bezeichnungen"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Bezeichnung"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={3}
                  variant="outlined"
                  value={deckblattInfo.BezeichnungAnlage || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BezeichnungAnlage: e.target.value })}
                />
              </Grid>
            </Grid>

            {/* Genehmigungsbehöre */}

            <CardHeader
              title="Genehmigungsbehörde"
            />

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12} >
                <TextField
                  id="outlined-multiline-static"
                  label="Info"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={3}
                  variant="outlined"
                  value={deckblattInfo.BezeichnungAnlage || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, Genehmigungsbehoerde: e.target.value })}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>

            {/* Anlageninfos Skeleton */}

            <CardHeader
              title="Betreiber Informationen"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6} >
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>

              <Grid item lg={6}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>

            {/* Anlageninfos Skeleton */}

            <CardHeader
              title="Anlagen Informationen"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>

              <Grid item lg={6}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>

            {/* Anlagenbezeichnung Skeleton */}

            <CardHeader
              title="Anlagenbezeichnungen"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12}>
                <Skeleton variant="text" />
              </Grid>
            </Grid>

            {/* Genehmigungsbehöre Skeleton */}

            <CardHeader
              title="Genehmigungsbehörde"
            />
            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12}>
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </>
        )
        }
      </CardContent >
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback error={error} success={success} />
        <LoaderButton
          isLoading={loading}
          text="Absenden"
          onClick={handleSubmit}
          disabled={!active}
        />
      </CardActions>
    </Card >
  );
}
