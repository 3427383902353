const dev = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://dev-api.emilog-portal.de/',
    // URL: 'https://dev-v2-api.emilog-portal.de/', // Irgendwann mal testen und anlegen
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_gsz1Uq7Co',
    APP_CLIENT_ID: 'ak2t6hnpse3igvbnrgffj43v5',
    IDENTITY_POOL_ID: 'eu-central-1:a69cf367-fd2f-4b66-9039-7f3169c71dab',
  },
  csvLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-csv-download/invocations',
  },
  mqtt: {
    ENDPOINT: 'wss://a2cg0yo8jbddsk-ats.iot.eu-central-1.amazonaws.com',
    REGION: 'eu-central-1',
  },
};

const prod = {
  apiGateway: {
    REGION: 'eu-central-1',
    // URL: 'https://api.emilog-portal.de/', // Original Prod
    URL: 'https://dev-api.emilog-portal.de/', // Workaround als Backup
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_gsz1Uq7Co',
    APP_CLIENT_ID: 'ak2t6hnpse3igvbnrgffj43v5',
    IDENTITY_POOL_ID: 'eu-central-1:a69cf367-fd2f-4b66-9039-7f3169c71dab',
  },
  csvLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-csv-download/invocations',
  },
  mqtt: {
    ENDPOINT: 'wss://a2cg0yo8jbddsk-ats.iot.eu-central-1.amazonaws.com',
    REGION: 'eu-central-1',
  },
};

// eslint-disable-next-line no-nested-ternary
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default config;
/*
$ aws cognito-idp sign-up \
  --region eu-central-1 \
  --client-id 6l9ek92ralt06abu6sa07p3pak \
  --username admin@example.com \
  --password Secret1!

  aws cognito-idp admin-confirm-sign-up \
  --region eu-central-1 \
  --user-pool-id eu-central-1_o5AZ6AzGK \
  --username admin@example.com
  */
