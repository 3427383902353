import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Skeleton from '@material-ui/lab/Skeleton';

import { makeStyles } from '@material-ui/core/styles';
import { API } from '@aws-amplify/api';
import Typography from '@material-ui/core/Typography';
import Chart from './Chart';

import ShadowGraphic from './ShadowGraphic';
import Button from '@material-ui/core/Button';

import { PubSub } from '@aws-amplify/pubsub';
import useStateRef from 'react-usestateref';
import { Refresh } from '@material-ui/icons';
import { EmiconShadow, MqttResponseEmicon } from '../../types/ShadowGraphic';
import EmiconBerichtDeckblattInfo from './EmiconBerichtDeckblattInfo';

const useStyles = makeStyles((theme) => ({
  chartSkeleton: {
    marginBottom: 24,
    marginTop: 24,
  },
  toggle: {
    padding: '6px 16px',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
  toggleGroup: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },

  noData: {
    padding: 36,
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },
}));

interface ChartDropdownProps {
  serialNumber: string
  showChart: boolean
  isEmicon?: boolean
  deckblattInfo: DeckblattInfo
}

export function ChartDropdown({
  serialNumber, showChart, isEmicon = false, deckblattInfo
}: ChartDropdownProps) {
  const classes = useStyles();
  const [valueMode, setValueMode] = useState('hmw');
  const [days, setDays] = useState(30);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [chartMode, setChartMode] = useState('all');

  const [chartData, setChartData] = useState({
    coTMW: [],
    coHMW: [],
    noxTMW: [],
    noxHMW: [],
    tempTMW: [],
    tempHMW: [],
    noxAlarm: [],
    coAlarm: [],
    tempAlarm: [],
    tempMin: [],
    tempMax: [],
  });

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        setNoData(false);
        const res = await API.get('emilog', 'chartData', { queryStringParameters: { serialNumber, amountOfDays: days, isEmicon } });
        setChartData(res);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
        setNoData(true);
        if (err.response.status === 404) {
          setNoData(true);
        }
      }
    }
    if (showChart) {
      fetch();
    }
  }, [days, isEmicon, serialNumber, showChart]);

  function handleChartToggle(event: any, newValue: string) {
    event.preventDefault();
    setChartMode(newValue);
  }

  function handleValueToggle(event: any, newValue: string) {
    event.preventDefault();
    setValueMode(newValue);
  }

  const renderToggle = () => (
    <ToggleButtonGroup
      exclusive
      value={chartMode}
      onChange={handleChartToggle}
      className={classes.toggleGroup}
    >
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="all"
      >
        Übersicht TMW
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="co"
      >
        CO
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="nox"
      >
        NOx
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="temp"
      >
        Temperatur
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="info"
      >
        Deckblatt Konfiguration
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const renderDaySelect = () => (
    <Select
      labelId="day-select-label"
      id="day-select"
      value={days}
      onChange={(event) => setDays(event.target.value as number)}
      renderValue={(value) => `${value} Tage`}
    >
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={60}>60</MenuItem>
      <MenuItem value={90}>90</MenuItem>
      <MenuItem value={120}>120</MenuItem>
      <MenuItem value={150}>150</MenuItem>
      <MenuItem value={180}>180</MenuItem>
      <MenuItem value={210}>210</MenuItem>
      <MenuItem value={240}>240</MenuItem>
      <MenuItem value={270}>270</MenuItem>
      <MenuItem value={300}>300</MenuItem>
      <MenuItem value={330}>330</MenuItem>
      <MenuItem value={360}>360</MenuItem>
    </Select>
  );

  const renderValueToggle = () => (
    <ToggleButtonGroup
      exclusive
      value={valueMode}
      onChange={handleValueToggle}
      className={classes.toggleGroup}
    >
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="hmw"
      >
        HMW
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="tmw"
      >
        TMW
      </ToggleButton>
    </ToggleButtonGroup>
  );

  /* Ab hier Livewerte */

  const subLive = useRef<MqttSubscription | null>(null);
  const subGet = useRef<MqttSubscription | null>(null);

  const [loadingLiveData, setLoadingLiveData, loadingLiveDataRef] = useStateRef(false);
  const [reported, setReported] = useState<Partial<EmiconShadow>>({});
  const [reportedTimestamp, setReportedTimestamp] = useState<number | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  function checkShadowValue(shadow: any, key1: string, key2: string, defaultValue: undefined | 'NaN') {
    if (Object.hasOwn(shadow, key1)) {
      shadow[key1] = shadow[key1];
    } else if (Object.hasOwn(shadow, key2)) {
      shadow[key1] = shadow[key2];
    } else {
      shadow[key1] = defaultValue;
    }
  }

  async function fetchShadow() {
    if (serialNumber && isEmicon) {
      // console.log("Fetching Shadow From:")
      // console.log({ serialNumber })
      try {
        const res = await API.get('emilog', 'getShadow', {
          queryStringParameters: {
            isEmicon: true,
            serialNumber,
          },
        }) as MqttResponseEmicon;
        // console.log({ serialNumber })
        // console.log({ res })
        setLoaded(true); // Shadowgraphic wurde geladen
        if (res.state.reported !== undefined && res.state.reported !== null) {
          checkShadowValue(res.state.reported, 'status', 'sStatus', 'NaN');
          checkShadowValue(res.state.reported, 'Var_RelO2', 'o2_bezug', undefined);
          checkShadowValue(res.state.reported, 'sSeriennummer', 'seriennummer', 'NaN'); // not sure about this one...
          checkShadowValue(res.state.reported, 'sVersion', 'version', 'NaN');
        }
        setReported((prevState: Partial<EmiconShadow>) => ({
          ...prevState,
          ...res.state.reported,
        }));
        setReportedTimestamp(
          res.metadata.reported?.status?.timestamp ??
          res.metadata.reported?.sStatus?.timestamp ??
          0
        );
      } catch (error: any) {
        console.log(error)
      }
    }
  }

  async function requestLiveValues() {
    if (serialNumber && isEmicon) {
      // if (serialNumber === 'EPSCR0000000002') console.log("EPSCR0000000002 hat requestet")
      PubSub.publish(`EMI-CON_${serialNumber}/EMI-CON/AWSDaten`, true);
      PubSub.publish(`$aws/things/EMI-CON_${serialNumber}/shadow/get`, {});
    }
  }

  function mapMqttResponse(value: MqttResponseEmicon) {
    if (value.state.reported !== undefined && value.state.reported !== null) {
      checkShadowValue(value.state.reported, 'status', 'sStatus', 'NaN');
      checkShadowValue(value.state.reported, 'Var_RelO2', 'o2_bezug', undefined);
      checkShadowValue(value.state.reported, 'sSeriennummer', 'seriennummer', 'NaN'); // not sure about this one...
      checkShadowValue(value.state.reported, 'sVersion', 'version', 'NaN');
    }
    // console.log("ShadowGraphic Live")
    // console.log({ value })
    setReported((prevState: Partial<EmiconShadow>) => ({
      ...prevState,
      ...value.state.reported,
    }));
    setReportedTimestamp(
      value.metadata.reported?.status?.timestamp ??
      value.metadata.reported?.sStatus?.timestamp ??
      0
    );
  }

  useEffect(() => {
    if (subLive.current) subLive.current!.unsubscribe();
    if (subGet.current) subGet.current!.unsubscribe();
    setReported({});
    if (serialNumber) {
      setLoading(true);
      fetchShadow();
      subLive.current = PubSub.subscribe(`$aws/things/EMI-CON_${serialNumber}/shadow/update/accepted`).subscribe({
        next: ({ value }) => {
          mapMqttResponse(value);
        },
        error: ((err) => {
          console.log(err);
        }),
        complete: () => {
          console.log('Subscription completed Update');
        },
      });
    }
    return function cleanUp() {
      if (subLive.current) subLive.current.unsubscribe();
      if (subGet.current) subGet.current.unsubscribe();
    };
  }, [serialNumber]);

  return (
    <Collapse in={showChart} timeout={200} unmountOnExit>
      <>
        <div className={classes.toggleContainer}>
          {isEmicon && chartMode !== 'info' ? (
            <Button
              disabled={loadingLiveData}
              onClick={requestLiveValues}
              className={loadingLiveData ? classes.fetchLiveDataButton : undefined}
              color="primary"
              variant="outlined"
              style={{ marginLeft: 16 }}
              startIcon={<Refresh />}
            >
              Update
            </Button>
          ) : (
            null
          )}
          {renderToggle()}
          {/* {renderDaySelect()} */}
          {chartMode !== 'info' ? renderDaySelect() : (null)}
          {chartMode !== 'all' && chartMode !== 'info' ? renderValueToggle() : (
            <div style={{
              width: 184,
            }}
            />
          )}
        </div>
        {!loading ? (
          <>
            {noData
              ? (
                <>
                  {chartMode === 'info' ? (
                    <EmiconBerichtDeckblattInfo serialNumber={serialNumber} isEmicon={isEmicon} deckblattInfoFromDevice={deckblattInfo} />
                  ) : (
                    <Typography className={classes.noData} component="h6">Keine Messdaten im angegeben Zeitraum aufgezeichnet</Typography>
                  )}
                </>
              ) : chartMode === 'all' ? (
                <Grid container spacing={2} alignItems="center">

                  {isEmicon ? (
                    <Grid item xs={12} lg={4} >
                      <ShadowGraphic serialNumber={serialNumber} isEmicon={isEmicon} reported={reported} loaded={loaded} reportedTimestamp={reportedTimestamp} />
                    </Grid>
                  ) : (
                    null
                  )
                  }

                  {!isEmicon ? (
                    <Grid item xs={12} lg={4}>
                      <Chart showHmw={valueMode === 'hmw'} days={days} type="co" chartData={chartData.coTMW} limits={chartData.coAlarm} />
                    </Grid>
                  ) : (
                    null
                  )
                  }

                  <Grid item xs={12} lg={4}>
                    <Chart showHmw={valueMode === 'hmw'} days={days} type="nox" chartData={chartData.noxTMW} limits={chartData.noxAlarm} />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Chart showHmw={valueMode === 'hmw'} days={days} type="temp" chartData={chartData.tempTMW} limits={chartData.tempAlarm} />
                  </Grid>

                </Grid >
              ) : chartMode === 'co' ? <Chart showHmw={valueMode === 'hmw'} days={days} type="co" chartData={valueMode === 'hmw' ? chartData.coHMW : chartData.coTMW} limits={chartData.coAlarm} fullSize />
                : chartMode === 'nox' ? <Chart showHmw={valueMode === 'hmw'} days={days} type="nox" chartData={valueMode === 'hmw' ? chartData.noxHMW : chartData.noxTMW} limits={chartData.noxAlarm} fullSize />
                  : chartMode === 'temp' ? <Chart showHmw={valueMode === 'hmw'} days={days} type="temp" chartData={valueMode === 'hmw' ? chartData.tempHMW : chartData.tempTMW} limits={chartData.tempAlarm} fullSize />
                    : chartMode === 'info' && <EmiconBerichtDeckblattInfo serialNumber={serialNumber} isEmicon={isEmicon} deckblattInfoFromDevice={deckblattInfo} />

            }
            {' '}
          </>
        ) : (
          <Grid container spacing={4} alignItems="center">
            {chartMode !== 'all' ? (
              <Grid item lg={12}>
                <Skeleton variant="rect" height={450} className={classes.chartSkeleton} />
              </Grid>
            ) : (
              <>

                {isEmicon ? (
                  <Grid item lg={4}>
                    <Skeleton variant="rect" height={450} className={classes.chartSkeleton} />
                  </Grid>
                ) : (
                  null
                )
                }

                {!isEmicon ? (
                  <Grid item lg={4}>
                    <Skeleton variant="rect" height={450} className={classes.chartSkeleton} />
                  </Grid>
                ) : (
                  null
                )
                }

                <Grid item lg={4}>
                  <Skeleton variant="rect" height={450} className={classes.chartSkeleton} />
                </Grid>

                <Grid item lg={4}>
                  <Skeleton variant="rect" height={450} className={classes.chartSkeleton} />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </>

    </Collapse >
  );
}
